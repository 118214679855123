// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBXsLqmm2zytrKDSw5LJac5qrTE8vWjn6A",
    authDomain: "wizilink-671af.firebaseapp.com",
    projectId: "wizilink-671af",
    storageBucket: "wizilink-671af.appspot.com",
    messagingSenderId: "252604213676",
    appId: "1:252604213676:web:d5d63f69f024b3efc3b209",
    measurementId: "G-R8L6ZZ4M16"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export const storage = getStorage(app)