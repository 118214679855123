import React from "react";
import Chat from "../../components/chat";

import CarouselComponent from "../../components/Carousel";

function Home() {
  return (
    <>
      <div id="home" className=" " />

      <div
        // id="home"
        className="w-full px-4 md:px-8 lg:px-8 pt-20 mt-20 flex gap-4 flex-col lg:flex-row "
      >
        <div className="w-[100%] lg:w-[40%]">
          <h1 className="text-2xl font-semibold">
            Welcome to Wizilink - Al to Empower Your Teams, Designed in Britain.
          </h1>

          <ul className="flex flex-col gap-6 mt-4 mb-8 ">
            <li className=" flex  ml-1">
              <h1 className="text-gray-500">
                Break down geographical boundaries, streamline compliance; and
                boost productivity effortlessly with Wizilink's advanced Al
                powered Q&A platform.
              </h1>
            </li>
            <li className=" flex  ml-1">
              <h1 className="text-gray-500">
                Unlike most Al applications, Wizilink complements your team,
                enhancing their abilities without replacing them.
              </h1>
            </li>
            <li className=" flex  ml-1">
              <h1 className="text-gray-500">
                At Wizilink your data privacy is paramount, ensuring security
                and confidentiality.
              </h1>
            </li>
            <li className=" flex  ml-1">
              <h1 className="text-gray-500">
                Discover more about our platform by scrolling down or simply ask
                our Demo Chatbot for information.{" "}
                <span className="text-gray-800 font-semibold">
                  Try it for yourself for free!
                </span>
              </h1>
            </li>
          </ul>
        </div>
        <div className="w-[100%] lg:w-[60%] aspect-square -mb-16 lg:-mb-48 relative hidden md:block">
          <img
            src="images/laptop.png"
            className="w-full  lg:-mt-[26%]
         
          aspect-square
          "
          />
          <div className="w-[80%] h-[49%] rounded-sm bg-yellow-700 absolute top-[24.5%] lg:-top-[1.8%] left-[10%]">
            <Chat />
          </div>
        </div>

        {/* ///mobile view */}

        <div className="w-[100%] relative block md:hidden overflow-hidden mb-10">
          <img
            src="images/mobile.png"
            className="  
        
          w-[100%]
          
          "
          />
          <div className="w-[67.5%] h-[76.6%]  bg-yellow-700 absolute top-[11.7%]  overflow-hidden lg:-top-[1.8%] left-[15%]">
            <Chat />
          </div>
        </div>
        {/* <div id="features" /> */}
      </div>
    </>
  );
}

export default Home;
