import React, { useEffect, useRef, useState } from "react";
import { BiChevronLeft } from "react-icons/bi";
import { HiOutlineLogout, HiOutlineDocumentText } from "react-icons/hi";
import { BsSendFill } from "react-icons/bs";
import { TbBrandGoogleAnalytics } from "react-icons/tb";
import { MdOutlineAdminPanelSettings } from "react-icons/md";
import logo from "../../assets/logos/whitenobg.png";
import useUser from "../../hooks/useUser";
import useSidebar from "../../hooks/useSidebar";
import MenuIcon from "../../components/MenuIcon";
import { TbCircleChevronRight } from "react-icons/tb";
import { BsRobot } from "react-icons/bs";
import { FaUser } from "react-icons/fa";
import randomSentence from "random-sentence";
import SyncLoader from "react-spinners/SyncLoader";
import toast from "react-hot-toast";
import axios from "axios";
import AutosizeInput from "react-input-autosize";
import { useLocation } from "react-router-dom";
import useCompanyData from "../../hooks/useCompanyData";
import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";
import WizilinkImage from "../../assets/logos/wizilink.png";

const documents = [
  {
    id: "1",
    name: "Students Record",
  },
  {
    id: "2",
    name: "Office Hours",
  },
  {
    id: "3",
    name: "Company Privacy Policy",
  },
  {
    id: "4",
    name: "Project Proposal",
  },
  {
    id: "5",
    name: "Employee Handbook",
  },
  // {
  //     id: '6',
  //     name: 'Financial Report'
  // },
  // {
  //     id: '7',
  //     name: 'Meeting Agenda'
  // },
  // {
  //     id: '8',
  //     name: 'Training Manual'
  // },
  // {
  //     id: '9',
  //     name: 'Research Paper'
  // },
  // {
  //     id: '10',
  //     name: 'Product Catalog'
  // },
  // {
  //     id: '11',
  //     name: 'Marketing Plan'
  // },
  // {
  //     id: '12',
  //     name: 'Terms and Conditions'
  // },
  // {
  //     id: '13',
  //     name: 'Project Specification'
  // },
  // {
  //     id: '14',
  //     name: 'User Manual'
  // },
  // {
  //     id: '15',
  //     name: 'Event Proposal'
  // },
  // {
  //     id: '16',
  //     name: 'Policy Manual'
  // },
  // {
  //     id: '17',
  //     name: 'Client Contract'
  // },
  // {
  //     id: '18',
  //     name: 'Quality Assurance Guidelines'
  // },
  // {
  //     id: '19',
  //     name: 'Troubleshooting Guide'
  // },
  // {
  //     id: '20',
  //     name: 'Product Brochure'
  // },
  // {
  //     id: '21',
  //     name: 'Code of Conduct'
  // },
  // {
  //     id: '22',
  //     name: 'Sales Agreement'
  // },
  // {
  //     id: '23',
  //     name: 'Job Application Form'
  // },
  // {
  //     id: '24',
  //     name: 'Project Timeline'
  // },
  // {
  //     id: '25',
  //     name: 'Investor Pitch Deck'
  // },
  // {
  //     id: '26',
  //     name: 'Client Presentation'
  // },
  // {
  //     id: '27',
  //     name: 'Vendor Contract'
  // }
];

const messagesTemp = [
  {
    id: "1",
    text: "What is your name?",
    from: "user",
  },
  {
    id: "2",
    text: "My name is Chatbot.",
    from: "ai",
  },
  {
    id: "3",
    text: "How are you?",
    from: "user",
  },
  {
    id: "4",
    text: `I'm doing well, thank you!`,
    from: "ai",
  },
  {
    id: "5",
    text: "What is the capital of France?",
    from: "user",
  },
  {
    id: "6",
    text: "The capital of France is Paris.",
    from: "ai",
  },
  {
    id: "7",
    text: "Tell me a joke.",
    from: "user",
  },
  {
    id: "8",
    text: `Why don't scientists trust atoms? Because they make up everything!`,
    from: "ai",
  },
  {
    id: "9",
    text: "What is the weather like today?",
    from: "user",
  },
  {
    id: "10",
    text: "The weather is sunny and warm.",
    from: "ai",
  },
  {
    id: "11",
    text: "Can you recommend a good movie?",
    from: "user",
  },
  {
    id: "12",
    text: 'Sure! I recommend "Inception".',
    from: "ai",
  },
  {
    id: "13",
    text: "How can I learn programming?",
    from: "user",
  },
  {
    id: "14",
    text: "You can start by learning the basics of a programming language and practicing coding exercises.Choose a programming language: Select a programming language based on your interests and the type of applications you want to build. Popular options include Python, JavaScript, Java, C++, and Ruby.",
    from: "ai",
  },
  {
    id: "15",
    text: "What is the meaning of life?",
    from: "user",
  },
  {
    id: "16",
    text: "The meaning of life is subjective and can vary for each individual.",
    from: "ai",
  },
  {
    id: "17",
    text: "Do you have any pets?",
    from: "user",
  },
  {
    id: "18",
    text: "As an AI, I don't have pets, but I love all animals!",
    from: "ai",
  },
  {
    id: "19",
    text: "How do I bake a cake?",
    from: "user",
  },
  {
    id: "20",
    text: "To bake a cake, you need to gather the ingredients, mix them together, and bake the batter in the oven.",
    from: "ai",
  },
  {
    id: "1",
    text: "What is your name?",
    from: "user",
  },
  {
    id: "2",
    text: "My name is Chatbot.",
    from: "ai",
  },
  {
    id: "3",
    text: "How are you?",
    from: "user",
  },
  {
    id: "4",
    text: `I'm doing well, thank you!`,
    from: "ai",
  },
  {
    id: "5",
    text: "What is the capital of France?",
    from: "user",
  },
  {
    id: "6",
    text: "The capital of France is Paris.",
    from: "ai",
  },
  {
    id: "7",
    text: "Tell me a joke.",
    from: "user",
  },
  {
    id: "8",
    text: `Why don't scientists trust atoms? Because they make up everything!`,
    from: "ai",
  },
  // Add more objects here...
];

function Chat() {
  const { companyData } = useCompanyData();
  const { menuHidden } = useSidebar();
  const [openDocuments, setOpenDocuments] = useState(false);
  const [selectedDocument, setSelectedDocument] = useState(
    companyData?.documents?.[0]
  );
  const [documentData, setDocumentData] = useState({});

  const textareaRef = useRef("");
  const messageScrollRef = useRef(null);
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const { user } = useUser();

  useEffect(() => {
    document.title = "Chat";
  }, []);

  //   console.log(messages);

  const dislikeMessage = async (message, index) => {
    if (message?.dislike === true || message?.like === true) {
      return;
    }

    const question = messages?.[index - 1].text;
    const answer = message.text;

    let messagesTemp = [...messages];
    messagesTemp[index] = {
      ...messagesTemp[index],
      like: false,
      dislike: true,
    };

    setMessages(messagesTemp);

    try {
      let questionSaved = await axios.post(
        `${process.env.REACT_APP_API_URL}/question/dislike`,
        {
          question: question,
          answer: answer,
          satisfied: false,
          documentId: selectedDocument?.id,
          // userId: user?.name,
          userName: user?.name,
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const likeMessage = (message, index) => {
    if (message?.dislike === true || message?.like === true) {
      return;
    }

    let messagesTemp = [...messages];
    messagesTemp[index] = {
      ...messagesTemp[index],
      like: true,
      dislike: false,
    };

    setMessages(messagesTemp);
  };

  const getDocument = async () => {
    if (selectedDocument?.id === "65feb1065cca5525f19fdc3c") {
      const doc = {
        name: "Talk with All",
        questions: [],
        id: "65feb1065cca5525f19fdc3c",
      };
      setDocumentData(doc);
    } else {
      try {
        const doc = await axios.get(
          `${process.env.REACT_APP_API_URL}/document/${selectedDocument?.id}`
        );
        setDocumentData(doc?.data);
      } catch (error) {
        console.log(error);
      }
    }
  };

  useEffect(() => {
    getDocument();
  }, [selectedDocument, isLoading]);
  useEffect(() => {
    setMessages([
      {
        id: "1",
        text: `Welcome to Wizilink!
                Feel free to ask anything about the document you've selected.`,
        from: "ai",
        welcome: true,
      },
    ]);
  }, [selectedDocument]);

  function autoGrow(e) {
    setInputText(e?.target?.value);
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  const askQuestion = async () => {
    if (inputText === "") {
      return;
    }

    try {
      setIsLoading(true);
      const reqData = {
        query: inputText,
        org: companyData?.name,
      };
      if (selectedDocument.id === "65feb1065cca5525f19fdc3c") {
      } else {
        reqData.filename = documentData?.name;
      }
      setMessages([
        ...messages,
        {
          id: "8",
          text: inputText,
          from: "user",
        },
      ]);
      setInputText("");

      const messagesTempo = [
        ...messages,
        {
          id: "8",
          text: inputText,
          from: "user",
        },
      ];

      if (messageScrollRef.current) {
        setTimeout(() => {
          messageScrollRef.current.scrollIntoView({ behavior: "smooth" });
        }, 0);
      }

      await axios.post(`${process.env.REACT_APP_API_URL}/user/addQuestion`, {
        userId: user.id,
      });

      let answer = null;

      let resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/question/getQuestion`,
        {
          question: inputText,
          documentId: documentData?.id,
        }
      );

      if (resp?.data?.answer) {
        answer = resp?.data?.answer;
      } else {
        const keyWord =
          selectedDocument.id === "65feb1065cca5525f19fdc3c"
            ? "query_org_doc"
            : "query_single_doc";
        resp = await axios.post(
          `${process.env.REACT_APP_AI_URL}/${keyWord}`,
          reqData
        );
        answer = resp?.data?.response;
      }

      if (answer) {
        await axios.post(`${process.env.REACT_APP_API_URL}/question`, {
          question: inputText,
          userName: user?.name,
          answer: answer,
          documentId: documentData?.id,
        });

        setMessages([
          ...messagesTempo,
          {
            id: "9",
            text: answer,
            like: false,
            dislike: false,
            from: "ai",
          },
        ]);
      }

      setIsLoading(false);

      if (messageScrollRef.current) {
        setTimeout(() => {
          messageScrollRef.current.scrollIntoView({ behavior: "smooth" });
        }, 50);
      }
    } catch (error) {
      setIsLoading(false);
      toast.error("Something went wrong");
      console.log(error);
    }
  };

  const MessageComponent = ({ message, index }) => {
    return (
      <div
        className={`flex max-w-[90%] gap-2 ${
          message.from === "user" && "ml-auto"
        }`}
      >
        <div className={`${message.from === "user" ? "order-2" : "order-1"}`}>
          {message.from === "user" ? (
            <FaUser size={20} className="text-lightblue" />
          ) : (
            //  <BsRobot size={20} className='text-gray-500' />
            // <img src={WizilinkImage} width={30} height={30} />
            <div className="relative overflow-hidden h-[30px] w-[30px]">
              <img src={WizilinkImage} className="object-cover" />
            </div>
          )}
        </div>
        <div
          className={`flex flex-col gap-1 ${
            message.from === "user" ? "order-1" : "order-2"
          }`}
        >
          <div
            className={` p-2 rounded-md whitespace-pre-line ${
              message.from === "user"
                ? "bg-blue text-white"
                : " bg-gray-300 text-gray-900"
            } `}
          >
            {/* {message.text} */}
            {message.text.replace(new RegExp("Sure, here's the formatted answer to your question:", 'g'), "").replace(/\n+/g, "\n").replace("{", "").replace("}", "").replace("context", "document")}
          </div>
          {message?.from === "ai" && !message?.welcome && (
            <div className={`order-2 ml-auto mr-2 flex flex-row gap-2`}>
              <AiOutlineLike
                onClick={() => likeMessage(message, index)}
                className={`${message?.like === true && "text-blue"}`}
              />
              <AiOutlineDislike
                className={`${message.dislike === true && "text-blue"}`}
                onClick={() => dislikeMessage(message, index)}
              />
            </div>
          )}
        </div>
      </div>
    );
  };

  const SuggestionComponent = ({ question }) => {
    return (
      <div
        onClick={() => {
          setInputText(question?.question);
        }}
        className="cursor-pointer bg-gray-500 text-white rounded-full p-1 pl-2 pr-2  text-sm"
      >
        {question?.question}
      </div>
    );
  };

  const handleAll = () => {
    const document = {
      id: "65feb1065cca5525f19fdc3c",
    };
    setSelectedDocument(document);
    setOpenDocuments(false);
  };

  return (
    <div
      className={`overflow-hidden  p-3   duration-300 ${
        !menuHidden && "rounded-tl-3xl"
      } h-full w-full`}
    >
      <div className="flex w-full h-full  ">
        <div className="h-full    w-full   border-r-[0px] relative">
          <div className=" p-2 flex h-[10%] bg-blue text-white rounded-md shadow-md overflow-hidden  items-center justify-between font-semibold">
            <MenuIcon />

            {documentData?.name?.split("-")[0]}

            <TbCircleChevronRight
              onClick={() => setOpenDocuments(!openDocuments)}
              className={`cursor-pointer text-[#eaeaea] ${
                openDocuments && "rotate-90"
              } duration-300`}
              size={28}
            />
          </div>

          {companyData?.documents?.length < 1 ? (
            <div className="h-[90%] flex justify-center items-center text-gray-700 font-semibold">
              No Documents Found!
            </div>
          ) : (
            <>
              <div
                className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg-[#eaeaea]  max-h-[90%]  gap-2 duration-300 pt-2 pb-2 absolute w-full rounded-md origin-top ${
                  !openDocuments && "scale-y-0"
                } z-10  overflow-y-scroll p-0 no-scrollbar`}
              >
                {companyData?.documents?.map((document) => (
                  <div
                    onClick={() => {
                      setSelectedDocument(document);
                      setOpenDocuments(false);
                    }}
                    key={document.id}
                    className={`p-3 w-full h-16 text-md rounded-md cursor-pointer ${
                      document?.id === selectedDocument?.id
                        ? "bg-white text-lightblue border-[1px] border-lightblue shadow-md font-bold"
                        : "bg-lightblue text-white shadow-sm border-[0px]"
                    } `}
                  >
                    {document.name?.split("-")[0]}
                  </div>
                ))}

                {companyData?.documents?.length !== 0 && (
                  <div
                    onClick={handleAll}
                    className={`p-3 w-full h-16 text-md rounded-md cursor-pointer ${
                      "65feb1065cca5525f19fdc3c" === selectedDocument?.id
                        ? "bg-white text-lightblue border-[1px] border-lightblue shadow-md font-bold"
                        : "bg-lightblue text-white shadow-sm border-[0px]"
                    } `}
                  >
                    Talk with All
                  </div>
                )}
              </div>

              <div
                className={`flex p-2 h-[90%] flex-col gap-2 duration-300 rounded-md  mb-4 overflow-y-scroll  no-scrollbar`}
              >
                <div className="flex flex-col flex-grow gap-3  overflow-scroll no-scrollbar">
                  <div className="mt-0" />
                  {messages.map((message, index) => {
                    return (
                      <MessageComponent
                        key={message?.id}
                        index={index}
                        message={message}
                      />
                    );
                  })}

                  <div ref={messageScrollRef} />
                </div>
                {
                  <div className="flex flex-row flex-wrap gap-2">
                    {documentData?.questions?.map((question) => (
                      <SuggestionComponent question={question} />
                    ))}
                  </div>
                }
                <div className="mt-auto flex flex-row items-center gap-2">
                  <textarea
                    placeholder="Talk with your document ..."
                    ref={textareaRef}
                    value={inputText}
                    onChange={autoGrow}
                    className="p-2 w-full outline-none  h-10 rounded-md bg-gray-300 min-h-[32px] max-h-[300px] overflow-y-auto"
                  />

                  {isLoading ? (
                    <SyncLoader
                      color="#137DC5"
                      size={7}
                      className={` mt-auto mb-4 z-0 `}
                    />
                  ) : (
                    <BsSendFill
                      onClick={() => {
                        askQuestion();
                      }}
                      className={`text-blue  duration-300 mt-auto z-0 cursor-pointer`}
                      size={28}
                    />
                  )}
                </div>
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default Chat;
