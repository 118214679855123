import React from "react";

function MenuIcon({ menuHidden, setMenuHidden }) {
  return (
    <div
      onClick={() => setMenuHidden(!menuHidden)}
      className="bg-transparent w-6 h-4 flex flex-col cursor-pointer items-center justify-between"
    >
      <div
        className={`w-full h-[3px] bg-gray-800  rounded-full ${
          !menuHidden ? "rotate-0" : "rotate-45"
        } duration-300 origin-[0] ${
          !menuHidden ? "-translate-y-[0px]" : "-translate-y-[4px]"
        }`}
      />
      <div
        className={`w-full h-[3px] bg-gray-800 duration-300 rounded-full ${
          !menuHidden ? "scale-100" : "scale-0"
        }`}
      />
      <div
        className={`w-full h-[3px] bg-gray-800  rounded-full ${
          !menuHidden ? "rotate-0" : "-rotate-45"
        } duration-300 origin-[0]`}
      />
    </div>
  );
}

export default MenuIcon;
