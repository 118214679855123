import React from "react";
import { motion } from "framer-motion";
import PricingCard from "../../components/PricingCard";

const basicFeatures = [
  `Access to the essential features of Wizilink like our intelligent Q&A and impressive analytics.`,
  `£4.99 per user per month.`,
  `Suitable for small teams who want to streamline their knowledge sharing and improve productivity.`,
];

const standardFeatures = [
  `All features of the Basic plan Wizilink like our intelligent Q&A and impressive analytics.`,
  `£9.99 per user per month.`,
  `Suitable for; growing teams or businesses with moderate needs.`,
];

const enterpriseFeatures = [
  `Our Enterprise tier provides extensive scalability and advanced functionality for large-scale organisations.`,
  `Contact us for a personalised quote.`,
  `Suitable for: Enterprises with complex needs, extensive integrations, and the desire for dedicated support and customisation options.`,
];
const greenFeatures = [
  `Our Green tier goes beyond providing software solutions. By choosing this tier, you actively.
    contribute to offsetting the environmental impact of server usage.`,
  `Pricing: dependent on use.`,
  `Suitable for:
    Environmentally conscious businesses that prioritise sustainability and want to align their operations with green initiatives.`,
];

function Pricing() {
  const headingAnimationVariant = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <div id="pricing" className="w-full px-4 md:px-8 lg:px-12 py-32 bg-white">
      <div className="flex items-center gap-2 mb-20">
        <div className="h-[1px] rounded-full bg-black w-full" />
        <motion.h1
          className="min-w-[150px] text-center"
          variants={headingAnimationVariant}
          initial="initial"
          whileInView="animate"
        >
          PRICING PLANS
        </motion.h1>
        <div className="h-[1px] rounded-full bg-black w-full" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2  mt-4 gap-4 ">
        <PricingCard
          price={4.99}
          title={`Business Basic Tier: Up to 5 Users`}
          features={basicFeatures}
          plan="basic"
          custom={false}
        />
        <PricingCard
          price={9.99}
          title={`Business 
          Standard Tier: Up
          to 20 Users`}
          features={standardFeatures}
          plan="standard"
          custom={false}
        />
        <PricingCard
          price={"£"}
          title={`Enterprise Tier: 
          Customisable 
          Number of Users`}
          features={enterpriseFeatures}
          custom={true}
          plan={"enterprise"}
        />
        <PricingCard
          price={"£"}
          title={`Green Tier - Offset 
          Your Emissions`}
          features={greenFeatures}
          custom={true}
          plan={"green"}
          green={true}
        />
      </div>
    </div>
  );
}

export default Pricing;
