import React from "react";
import { motion } from "framer-motion";
import UsecaseCard from "../../components/UsecaseCard";

const user = [
  `Seeks convenient access to 
  relevant knowledge and quick
  resolutions to his queries.
  `,
  `Experiences a user-friendly 
  platform with a vast 
  repository of line manager 
  uploaded information.`,
  `Benefits from an intuitive 
  search functionality for 
  finding answers and 
  accessing training materials.`,
  `Saves time, enhances 
  productivity, and gains 
  confidence in problem-solving
  with Wizilink.`,
];

const lineManager = [
  `Faces challenges in identifying training 
  needs and assessing team members' 
  skill levels.`,
  `Embraces Wizilink's interactive platform,
  where team members can ask questions 
  and receive approved answers, creating 
  a centralised knowledge base.`,
  `Gains valuable insights into types of 
  questions asked, knowledge gaps, and 
  individual team members' expertise with
  Wizilink's analytics feature.`,
  `Utilises analytics to identify training 
  needs, develop targeted training 
  programs, and optimise team 
  performance.`,
  `Wizilink empowers Laura to make data-driven decisions, fostering a more 
  knowledgeable and efficient team.`,
];
const leader = [
  `Leverages Wizilink's centralised 
  platform, enabling team members to
  ask questions and receive approved 
  answers.`,
  `Gains valuable insights into types of 
  questions asked, knowledge gaps 
  across teams, and individual team 
  members' expertise with Wizilink's 
  analytics feature.`,
  `Utilises analytics to identify training 
  needs, allocate resources effectively,
  and optimise team performance.`,
  `Wizilink empowers Michael to make 
  data-driven decisions, improve 
  overall team productivity, and 
  achieve better outcomes for the 
  organisation.`,
];

function Usecases() {
  const headingAnimationVariant = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  return (
    <div id="usecases" className="w-full px-4 md:px-8 lg:px-12 py-32 ">
      <div className="flex items-center gap-2 mb-20">
        <div className="h-[1px] rounded-full bg-black w-full" />
        <motion.h1
          className="min-w-[150px] text-center"
          variants={headingAnimationVariant}
          initial="initial"
          whileInView="animate"
        >
          Use Cases
        </motion.h1>
        <div className="h-[1px] rounded-full bg-black w-full" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3  mt-4 gap-4 ">
        <UsecaseCard
          title={`Meet Sam the End User:`}
          image="/images/1.jpg"
          cases={user}
        />
        <UsecaseCard
          image="/images/2.jpg"
          title={`Meet Laura, the Line Manager:`}
          cases={lineManager}
          center={true}
        />
        <UsecaseCard
          image="/images/3.jpg"
          title={`Meet Michael, the Empowered Team
          Leader:`}
          cases={leader}
        />
      </div>
    </div>
  );
}

export default Usecases;
