import React from 'react'
import Home from './screens/Home'

import Features from './screens/Features'
import HowItWorks from './screens/HowItWorks'
import Usecases from './screens/usecases'
import Pricing from './screens/pricing'
import Footer from './screens/Footer'
import Faqs from './screens/faqs'


function All() {
    return (
        <>
            <Home />
            <Features />
            <HowItWorks />
            <Usecases />
            <Pricing />
            <Faqs />
            <Footer />
        </>
    )
}

export default All