import React, { useMemo, useState } from "react";
import useCompanyModal from "../../hooks/useCompanyModal";
import Modal from "./Modal";
import Input from "../Input";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axios from "axios";
import { storage } from "../../utils/firebase";
import { ref, getDownloadURL, uploadBytesResumable } from "firebase/storage";
import useDocumentModal from "../../hooks/useDocumentModal";
import { v4 as uuidv4 } from "uuid";
import useCompanyData from "../../hooks/useCompanyData";
function DocumentModal() {
  const { companyData } = useCompanyData();
  const documentModal = useDocumentModal();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      document: null,
    },
  });

  const uploadDocument = (file, name) => {
    return new Promise((resolve, reject) => {
      const storageRef = ref(storage, `documents/${name}`);
      const uploadTask = uploadBytesResumable(storageRef, file);

      uploadTask.on(
        "state_changed",
        (snapshot) => {
          const progress = Math.round(
            (snapshot.bytesTransferred / snapshot.totalBytes) * 100
          );
        },
        (error) => {
          console.log(error);
          reject(error);
        },
        async () => {
          const downloadUrl = await getDownloadURL(uploadTask.snapshot.ref);

          resolve(downloadUrl);
        }
      );
    });
  };

  const onSubmit = async (data) => {
    const date = new Date().getTime();
    const extension = data?.document[0].name.split(".").pop();

    try {
      setIsLoading(true);
      const storageReferenceName = uuidv4();
      const url = await uploadDocument(data?.document[0], storageReferenceName);
      // const url = "https://www.google.com";
      toast.success("Training...");
      const filename = `${data.name}-${date}.${extension}`;

      const formData = {
        filename,
        file_url: url,
        org: companyData.name,
      };

      const response = await axios.post(
        `${process.env.REACT_APP_AI_URL}/${"create_embeddings"}`,
        formData
      );

      if (response.data.response) {
        const doc = {
          name: filename,
          url: url,
          extension: data?.document[0].name.split(".").slice(-1)[0],
          storageReferenceName: storageReferenceName,
          companyId: companyData?.id,
        };
        await axios.post(
          `${process.env.REACT_APP_API_URL}/document`,
          (data = doc)
        );
        reset();
        toast.success("Done");
        documentModal.onClose();
      }
    } catch (error) {
      toast.error("Something went wrong!");
    } finally {
      setIsLoading(false);
    }
  };

  const bodyContent = (
    <div className="">
      <Input
        label="Document Name"
        id="name"
        required={true}
        register={register}
        errors={errors}
      />
      <Input
        label="Document"
        id="document"
        accept=".pdf,.doc,.docx , .txt"
        type="file"
        required={true}
        register={register}
        errors={errors}
      />
    </div>
  );

  return (
    <Modal
      isOpen={documentModal.isOpen}
      onClose={documentModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      title={"Upload Document"}
      actionLabel="Upload"
      disabled={isLoading}
      body={bodyContent}
      // footer={footerContent}
    />
  );
}

export default DocumentModal;
