import React, { useState } from 'react'
import { BiChevronLeft } from 'react-icons/bi'
import { HiOutlineLogout, HiOutlineDocumentText } from 'react-icons/hi'
import { BsChatLeftDots, } from 'react-icons/bs'
import { FiMail } from 'react-icons/fi'
import { TbBrandGoogleAnalytics } from 'react-icons/tb'
import { MdOutlineAdminPanelSettings } from 'react-icons/md'
import logo from '../assets/logos/image.png'
import useUser from '../hooks/useUser'
import useSidebar from '../hooks/useSidebar'
import { Link, useLocation, useNavigate } from 'react-router-dom'


function Sidebar({ children }) {

    const { pathname } = useLocation()
    const navigate = useNavigate()

    const { user, setUser } = useUser()
    // const [isOpen, setIsOpen] = useState(true)
    // const [menuHidden, setMenuHidden] = useState(false)
    const { isOpen, setIsOpen, menuHidden, setMenuHidden } = useSidebar()

    const logout = () => {
        localStorage.removeItem('jwt_token')
        setUser(null)
        navigate('/')
    }

    return (
        <div className='flex h-full w-full bg-blue'>
            <div className={`${isOpen ? 'w-72' : 'w-20'} ${menuHidden ? `-translate-x-full  overflow-hidden p-0 scale-x-0  ${isOpen ? '-mr-72' : '-mr-20'}` : 'translate-x-0'}    duration-300 h-screen bg-blue relative px-4 pt-8`}>
                <div onClick={() => setIsOpen(!isOpen)} className={`${!isOpen && 'rotate-180'} duration-300 absolute cursor-pointer -right-[11px] top-9 w-6 border-2 pl-[1px]  h-6 flex justify-center items-center text-blue rounded-full bg-white border-blue`}>
                    <BiChevronLeft size={23} />
                </div>

                <div className="flex flex-col gap-x-4  ">
                    <div className={` w-14 h-14 overflow-hidden relative duration-300 rounded-md p-[2px] mx-auto ${isOpen && 'scale-150'}`}>
                        <img src={logo} />
                    </div>

                    <ul className='pt-6 space-y-1'>
                        {(user.role === 'ADMIN' || user.role === 'USER') && <li >
                            <Link to='/chat' className={`flex gap-x-2 p-2 items-center ${pathname === '/chat' && 'bg-[rgba(0,0,0,0.2)] hover:bg-[rgba(0,0,0,0.1)]'} hover:bg-[rgba(0,0,0,0.2)] rounded-md`}>
                                <BsChatLeftDots size={24} color={'#fff'} />
                                <div className={`${!isOpen && 'hidden'} duration-300 text-white text-sm font-semibold`}>Chat</div>
                            </Link>
                        </li>}
                        {(user.role === 'ADMIN') && <li >
                            <Link to='/documents' className={`flex gap-x-2 p-2 items-center ${pathname === '/documents' && 'bg-[rgba(0,0,0,0.2)] hover:bg-[rgba(0,0,0,0.1)]'} hover:bg-[rgba(0,0,0,0.2)] rounded-md`}>
                                <HiOutlineDocumentText size={24} color={'#fff'} />
                                <div className={`${!isOpen && 'hidden'} duration-300 text-white text-sm font-semibold`}>Documents</div>
                            </Link>
                        </li>}
                        {(user.role === 'SUPERADMIN') && <li >
                            <Link to='/enquiry' className={`flex gap-x-2 p-2 items-center ${pathname === '/enquiry' && 'bg-[rgba(0,0,0,0.2)] hover:bg-[rgba(0,0,0,0.1)]'} hover:bg-[rgba(0,0,0,0.2)] rounded-md`}>
                                <FiMail size={23} color={'#fff'} />
                                <div className={`${!isOpen && 'hidden'} duration-300 text-white text-sm font-semibold`}>Enquiries</div>
                            </Link>
                        </li>}
                        {(user.role === 'ADMIN') && <li >
                            <Link to='/analytics' className={`flex gap-x-2 p-2 items-center ${pathname === '/analytics' && 'bg-[rgba(0,0,0,0.2)] hover:bg-[rgba(0,0,0,0.1)]'} hover:bg-[rgba(0,0,0,0.2)] rounded-md`}>
                                <TbBrandGoogleAnalytics size={25} color={'#fff'} />
                                <div className={`${!isOpen && 'hidden'} duration-300 text-white text-sm font-semibold`}>Analytics</div>
                            </Link>
                        </li>}
                        {(user.role === 'SUPERADMIN') && <li >
                            <Link to='/admin' className={`flex gap-x-2 p-2 items-center ${pathname === '/admin' && 'bg-[rgba(0,0,0,0.2)] hover:bg-[rgba(0,0,0,0.1)]'} hover:bg-[rgba(0,0,0,0.2)] rounded-md`}>
                                <MdOutlineAdminPanelSettings size={25} color={'#fff'} />
                                <div className={`${!isOpen && 'hidden'} duration-300 text-white text-sm font-semibold`}>Admin</div>
                            </Link>
                        </li>}

                        <li onClick={logout} className='flex gap-x-2 p-2 mt-1 items-center  hover:bg-[rgba(0,0,0,0.2)] rounded-md'>
                            <HiOutlineLogout size={25} color={'#fff'} />
                            <div className={`${!isOpen && 'hidden'} duration-300 text-white text-sm font-semibold`}>Logout</div>
                        </li>
                    </ul>


                </div>
            </div>
            <div className='h-screen bg-[#eaeaea] w-screen '>
                {children}
            </div>

        </div>
    )
}

export default Sidebar