
import React from "react";
import Select from "react-select";

function SelectInput({ id, label, value, onChange, values, errors, validate }) {




    return (
        <div>
            <label className='
    block
     text-sm 
     font-medium 
     leading-6
      text-lightblack
      mb-1
      '

            >
                {label}
            </label>
            <Select

                options={values}
                value={value}
                onChange={(value) => {
                    validate(value)
                    onChange(value)
                }}
                formatOptionLabel={(option) => {
                    return (
                        <div className="flex flex-row">

                            <div className="ml-0">
                                {option.label}

                            </div>
                        </div>
                    );
                }}
                classNames={{
                    control: () => "text-sm p-0 border-[1px]",
                    input: () => "text-lg",
                }}
                theme={(theme) => ({
                    ...theme,
                    borderRadius: 6,
                    colors: {
                        ...theme.colors,
                        primary25: "#d0d0d0",
                        primary: "#2199ea",
                    },
                })}
            />
            {errors[id] && <span className="text-sm text-rose-500 ml-1">Required</span>}
        </div>
    );
}

export default SelectInput;
