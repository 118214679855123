import React, { useEffect, useRef } from "react";
import { motion } from "framer-motion";
import { Upload, Languages, Rocket, Activity, BarChart4 } from "lucide-react";
import HowItWorksCard from "../../components/howItWorksCard";
import CarouselComponent from "../../components/Carousel";

function HowItWorks() {
  const headingAnimationVariant = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  return (
    <div
      id="howitworks"
      className="w-full px-4 md:px-8 lg:px-12 py-32 bg-[#f8f9fcff]"
    >
      <div className="flex items-center gap-2 mb-20">
        <div className="h-[1px] rounded-full bg-black w-full" />
        <motion.h1
          className="min-w-[150px] text-center"
          variants={headingAnimationVariant}
          initial="initial"
          whileInView="animate"
        >
          HOW IT WORKS
        </motion.h1>
        <div className="h-[1px] rounded-full bg-black w-full" />
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 mt-4 items-center ">
        <div className="relative p-8 items-center flex justify-center">
          {/* <HowItWorksAnimation /> */}
          <div className=" max-h-[80%] w-full">
            <CarouselComponent />
          </div>
        </div>
        <div className="">
          <p className="text-gray-500 text-justify">
            Say goodbye to the days of navigating cumbersome corporate document
            libraries or tirelessly searching for critical details. Wizilink
            introduces a seamless approach, allowing you to converse naturally
            with the app to swiftly find the information you seek.
          </p>

          <h1 className="uppercase text-lg font-bold my-6">
            Here's how it works
          </h1>

          <div className="flex flex-col gap-6">
            <HowItWorksCard
              Icon={Upload}
              title={`Upload Company Documents:`}
              description={` Managers can easily upload company 
documents to Wizilink, ranging from policies and procedures to contracts 
and guidelines.`}
            />
            <HowItWorksCard
              Icon={Languages}
              title={`Natural Language Interaction:`}
              description={` You can communicate with Wizilink just as you
              would with a colleague. No need for complex keywords or specific phrases – 
              simply ask your questions naturally, and Wizilink will understand.`}
            />
            <HowItWorksCard
              Icon={Rocket}
              title={`Fast and Accurate Responses:`}
              description={` Using state-of-the-art AI technology, Wizilink 
              processes your queries and provides rapid, accurate responses, bringing the
              information you need right to your fingertips.`}
            />
            <HowItWorksCard
              Icon={Activity}
              title={`Frequently Asked Questions:`}
              description={` To save you time and effort, Wizilink provides a
              curated list of the most frequently asked questions. You can simply click on 
              a question to get an instant answer, eliminating the need to type it out.`}
            />
            <HowItWorksCard
              Icon={BarChart4}
              title={`Enhanced Analytics:`}
              description={` We've integrated advanced analytics into Wizilink, 
              allowing users to rate responses and providing managers with insights into 
              the most common questions and potential training gaps.`}
            />
          </div>
        </div>
      </div>
    </div>
  );
}

export default HowItWorks;
