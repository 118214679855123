import React, { useMemo, useState } from 'react'
import useCompanyModal from '../../hooks/useCompanyModal'
import Modal from './Modal'
import Input from '../Input'
import { useForm } from 'react-hook-form'
import toast from 'react-hot-toast'
import axios from 'axios'
function CompanyModal() {

    const companyModal = useCompanyModal()
    const [isLoading, setIsLoading] = useState(false)


    const { register, handleSubmit, reset, formState: { errors } } = useForm({
        defaultValues: {
            name: ''
        }
    })


    const onSubmit = async (data) => {

        try {
            setIsLoading(true)

            await axios.post(`${process.env.REACT_APP_API_URL}/company`, data)
            reset()
            companyModal.onClose()
            toast.success('Created Successfully')

        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data === 'P2002' ? 'Company already exists' : 'Something went wrong!')
        }
        finally {
            setIsLoading(false)
        }


    }




    const bodyContent = <div className="" >
        <Input label='Company Name' id='name' required={true} register={register} errors={errors} />
    </div>


    return (
        <Modal
            isOpen={companyModal.isOpen}
            onClose={companyModal.onClose}
            onSubmit={handleSubmit(onSubmit)}
            title={"Create Company"}
            actionLabel="Continue"
            disabled={isLoading}
            body={bodyContent}
        // footer={footerContent}
        />
    )
}

export default CompanyModal