import React, { useState } from 'react'

import Modal from './Modal'
import useMemberModal from '../../hooks/useMemberModal'
import Input from '../Input'
import { useForm } from 'react-hook-form'
import SelectInput from '../SelectInput'
import { toast } from 'react-hot-toast'
import axios from 'axios'
function MemberModal({ companies }) {

    const memberModal = useMemberModal()
    const [isLoading, setIsLoading] = useState(false)

    const { register,
        handleSubmit,
        watch,
        setValue,
        setError,
        clearErrors,
        reset,
        formState: { errors }
    } = useForm({
        defaultValues: {
            email: '',
            name: ''
        }
    })


    const company = watch('company')
    const role = watch('role')

    const setCustomValue = (id, value) => {

        setValue(id, value, {
            shouldValidate: true,
            shouldDirty: true,
            shouldTouch: true,
        });
    };


    const validateCompany = (value) => {
        if (!value) {
            setError('company', {
                type: 'required',
                message: 'Company is required',
            });
        } else {
            clearErrors('company');
        }
    };

    const validateRole = (value) => {
        if (!value) {
            setError('role', {
                type: 'required',
                message: 'Role is required',
            });
        } else {
            clearErrors('role');
        }
    };



    const onSubmit = handleSubmit(async (data) => {



        try {
            setIsLoading(true)

            await axios.post(`${process.env.REACT_APP_API_URL}/user`, {
                companyId: data.company.value,

                email: data.email,
                role: data.role.value,
                name: data?.name
            })
            reset()
            memberModal.onClose()
            toast.success('Created Successfully')

        } catch (error) {
            console.log(error)
            toast.error(error?.response?.data === 'P2002' ? 'User already exists' : 'Something went wrong!')
        }
        finally {
            setIsLoading(false)
        }


    })


    const roles = [
        {
            label: 'USER',
            value: 'USER'
        },
        {
            label: 'ADMIN',
            value: 'ADMIN'
        },
        {
            label: 'SUPERADMIN',
            value: 'SUPERADMIN'
        },

    ]


    const bodyContent = <div className="flex flex-col gap-4" >
        <SelectInput
            id='company'
            label='Company'
            value={company}
            onChange={(value) => setCustomValue("company", value)}
            values={companies.map((comp) => {
                return {
                    label: comp.name,
                    value: comp.id
                }
            })}
            errors={errors}
            validate={validateCompany}
        />
        <Input label='Name' id='name' required={true} register={register} errors={errors} />

        <Input label='Email' id='email' required={true} register={register} errors={errors} />

        <SelectInput
            id='role'
            label='Role'
            value={role}
            onChange={(value) => setCustomValue("role", value)}
            values={roles}
            errors={errors}
            validate={validateRole}
        />

    </div>



    return (
        <Modal
            isOpen={memberModal.isOpen}
            onClose={memberModal.onClose}
            onSubmit={() => {
                validateCompany(company)
                validateRole(role)
                onSubmit()
            }}
            title={"Create Member"}
            actionLabel="Continue"
            disabled={isLoading}
            body={bodyContent}
        // footer={footerContent}
        />
    )
}

export default MemberModal