import React from "react";
import { Facebook, Linkedin, Twitter } from "lucide-react";

function Footer() {
  return (
    <div className="w-full pt-20  flex flex-col justify-center items-center bg-gray-100">
      <img src="/images/logos/colornobg.png" className="w-48" />
      <div className="flex flex-row gap-4 mt-5">
        <a
          href="https://www.facebook.com/profile.php?id=100095067465699"
          target="_blank"
          className="w-11 h-11 border-[2px] cursor-pointer rounded-full flex items-center justify-center group hover:border-blue duration-300 border-gray-600"
        >
          <Facebook className="text-gray-600 group-hover:text-blue" />
        </a>
        <a
          href="https://www.linkedin.com/company/wizilink-ai/"
          target="_blank"
          className="w-11 h-11 border-[2px] cursor-pointer rounded-full flex items-center justify-center group hover:border-blue duration-300 border-gray-600"
        >
          <Linkedin className="text-gray-600 group-hover:text-blue" />
        </a>
        <a
          href="https://twitter.com/WizilinkAI"
          target="_blank"
          className="w-11 h-11 border-[2px] cursor-pointer rounded-full flex items-center justify-center group hover:border-blue duration-300 border-gray-600"
        >
          <Twitter className="text-gray-600 group-hover:text-blue" />
        </a>
      </div>

      <div className="w-full border-t-[1px] mt-4 flex items-center justify-center pt-7 pb-4">
        <p className="text-gray-500">Powered by Wizilink</p>
      </div>
    </div>
  );
}

export default Footer;
