import React, { useEffect } from 'react'
import Login from './screens/auth/Login'
import AddData from './screens/app/superadmin/addData'
import { Toaster } from 'react-hot-toast'
import useUser from './hooks/useUser'
import { decodeToken } from "react-jwt";
import Chat from './screens/app/chat'
import Sidebar from './components/Sidebar'
import { Navigation } from './screens/app/navigation'
import { AuthNavigation } from './screens/auth/authNavigation'

function App() {

  const { user, setUser } = useUser()



  const checkUser = () => {
    const token = localStorage.getItem('jwt_token')

    if (!token) {
      setUser(null)
    } else {
      const decodedToken = decodeToken(token)
      setUser(decodedToken)
    }
  }


  useEffect(() => {

    checkUser()

  }, [])




  if (user === 'loading') {
    return <div className="flex w-full h-[100vh] justify-center items-center">loading...</div>
  }

  if (!user) {
    return <>
      {/* <Login /> */}
      <AuthNavigation />
      <Toaster

      />
    </>
  }



  return (
    <>

      {/* <AddData /> */}
      <Sidebar>
        <Navigation />
      </Sidebar>

      <Toaster

      />
    </>

  )
}

export default App