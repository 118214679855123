import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
// import Chart from 'react-apexcharts'
import ApexCharts from "apexcharts";

const StatComponent = ({ document, navigate }) => {
  const questionsLength = document?.questions?.length;
  const objectionsLength = document?.objectionQuestions?.length;
  const faqs = document?.questions?.filter((q) => q.frequency > 1);
  const faqsLength = faqs?.length;


  console.log("Questions Length", questionsLength);
  console.log("Objections Length", objectionsLength);

  console.log("FAQS Length", faqsLength);

  const objectionsWidth = (objectionsLength / questionsLength) * 100;
  const questionsWidth = 100 - objectionsWidth;
  const [isVisible, setisVisible] = useState(true);

  const isZero =
    objectionsWidth.toString() === "NaN" && questionsWidth.toString() === "NaN";

  const chartRef = useRef("");
  let chart;

  useEffect(() => {
    const series = [questionsLength, objectionsLength, faqsLength];
    const options = {
      chart: {
        type: "donut",

        events: {
          dataPointSelection: (event, chartContext, config) => {
            const ind = config?.dataPointIndex;

            if (ind === 0) {
              navigate("/records", {
                state: {
                  messages: document?.questions,
                  documentName: document?.name,
                  title: "Questions",
                },
              });
            }
            if (ind === 1) {
              navigate("/records", {
                state: {
                  messages: document?.objectionQuestions,
                  documentName: document?.name,
                  title: "Objections",
                },
              });
            }
            if (ind === 2) {
              navigate("/records", {
                state: {
                  messages: faqs,
                  documentName: document?.name,
                  title: "FAQs",
                },
              });
            }
          },
        },
      },
      labels: ["Questions", "Objections", "FAQs"],
      series: series,
      responsive: [
        {
          breakpoint: 760,
          options: {
            chart: {
              width: 300,
            },
            legend: {
              position: "bottom",
            },
          },
        },
      ],

      plotOptions: {
        pie: {
          // customScale: 0.8,
          expandOnClick: false,
        },
      },
      colors: ["#008ffb", "#FF0C0C", "#fbb31d"],

      dataLabels: {
        formatter: function (val, { seriesIndex }) {
          console.log(seriesIndex);
          if (seriesIndex === 0) {
            return questionsLength;
          }
          if (seriesIndex === 1) {
            return objectionsLength;
          }
          if (seriesIndex === 2) {
            return faqsLength;
          }
          // Format label to display as numbers without decimals
        },
      },
    };

    if (chartRef.current) {
      chart = new ApexCharts(chartRef.current, options);
      chart.render();
    }

    return () => {
      if (chartRef.current) {
        chart?.destroy();
      }
    };
  }, [navigate, chartRef.current]);

  return (
    <div
      onClick={() => {}}
      key={document.id}
      className={`p-3 w-full  text-md font-[500] rounded-sm flex flex-col  bg-white   text-gray-600 shadow-lg border-[0px] `}
    >
      <div>{document.name?.split("-")[0]}</div>

      {questionsLength > 0 ? (
        <div
          id="chart"
          ref={chartRef}
          className="flex  items-center justify-center"
        >
          {/* <Chart options={options}
                series={[questionsLength, objectionsLength, faqsLength]} type="donut" width={'100%'} height={'100%'} /> */}
        </div>
      ) : (
        <div className="flex items-center justify-center w-full h-full">
          No Stats to Show!
        </div>
      )}

      {/* <div className={`rounded-sm mt-2 w-full flex flex-row  items-center  text-white`}>


            <div style={{ width: `${isZero ? 50 : questionsWidth}%` }} className={`text-sm rounded-sm  bg-green-500  overflow-hidden text-center`}>
                {questionsLength - objectionsLength}
            </div>
            <div onClick={() => navigate('/objections', {
                state: {
                    messages: document?.objectionQuestions,
                    documentName: document?.name
                }
            })} style={{ width: `${isZero ? 50 : objectionsWidth}%` }} className={`text-sm bg-red-500 cursor-pointer overflow-hidden text-center duration-300 hover:scale-y-105 hover:shadow-md rounded-sm`}>
                {objectionsLength}

            </div>

            {faqs?.length > 0 && <div className="ml-1 w-5 h-5 text-sm bg-orange-500 cursor-pointer overflow-hidden text-center duration-300 hover:scale-y-105 hover:shadow-md rounded-sm">
                {faqs?.length}
            </div>}
        </div> */}

      {/* <div className=' '>
            {document?.questions?.length}
        </div>
        <div onClick={() => navigate('/objections', {
            state: {
                messages: document?.objectionQuestions,
                documentName: document?.name
            }
        })} className=' text-rose-500 cursor-pointer'>
            {document?.objectionQuestions?.length}
        </div> */}
    </div>
  );
};

export default StatComponent;
