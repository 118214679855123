import React, { useEffect, useRef, useState } from "react";
import { BsChevronCompactLeft, BsChevronCompactRight } from "react-icons/bs";
import { RxDotFilled } from "react-icons/rx";

function CarouselComponent() {
  const [currentIndex, setCurrentIndex] = useState(0);

  const slides = [
    {
      url: `/images/gifs/1.gif`,
    },
    {
      url: `/images/gifs/2.gif`,
    },
    {
      url: `/images/gifs/3.gif`,
    },
    {
      url: `/images/gifs/4.gif`,
    },
    {
      url: `/images/gifs/5.gif`,
    },
  ];
  const ind = useRef(0);

  const onPrev = () => {
    const isFirst = ind.current === 0;
    const newIndex = isFirst ? slides.length - 1 : ind.current - 1;
    ind.current = newIndex * 1;
    setCurrentIndex(newIndex);
  };
  const onNext = () => {
    const isLast = ind.current * 1 === slides.length - 1;
    const newIndex = isLast ? 0 : ind.current + 1;
    ind.current = newIndex * 1;
    setCurrentIndex(newIndex);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      onNext();
    }, 10000);

    // return clearInterval(interval);
  }, []);

  return (
    <div
      style={{ backgroundImage: `url(${slides[ind.current].url})` }}
      className="group w-full aspect-[7/7] rounded-xl bg-center bg-cover duration-500 flex justify-center"
    >
      <div
        onClick={() => onPrev()}
        className="hidden group-hover:block absolute top-[50%] -translate-x-0 p-1 translate-y-[-50%] left-10 text-2xl bg-black/20 text-white cursor-pointer rounded-full"
      >
        <BsChevronCompactLeft size={25} />
      </div>
      <div
        onClick={() => onNext()}
        className="hidden group-hover:block absolute top-[50%] -translate-x-0 p-1 translate-y-[-50%] right-10 text-2xl bg-black/20 text-white cursor-pointer rounded-full"
      >
        <BsChevronCompactRight size={25} />
      </div>

      <div className="flex  justify-center py-2 absolute bottom-8">
        {slides.map((sl, slideIndex) => (
          <div
            className={`text-2xl cursor-pointer ${
              slideIndex === currentIndex ? "text-blue" : "text-gray-800"
            }`}
          >
            <RxDotFilled />
          </div>
        ))}
      </div>
    </div>
  );
}

export default CarouselComponent;
