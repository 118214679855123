import React from "react";
import { Dot } from "lucide-react";

function UsecaseCard({ title, image, cases, center }) {
  return (
    <div className="bg-[#f2f5f5ff] p-4 rounded-xl flex flex-col items-center gap-4 justify-between">
      <div className={`${center && "order-2"}`}>
        <h1 className="font-semibold">{title}</h1>

        <ul className="flex flex-col gap-6 mt-4 mb-8">
          {cases.map((text) => (
            <li className=" flex  ml-1">
              <h1 className="text-gray-500">{text}</h1>
            </li>
          ))}
        </ul>
      </div>

      <img src={image} className="w-32 aspect-square rounded-full " />
    </div>
  );
}

export default UsecaseCard;
