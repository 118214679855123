import React, { useEffect, useState } from "react";
import Button from "../Button";
import { motion } from "framer-motion";
import { Menu } from "lucide-react";
import MenuIcon from "../MenuIcon";
import { useNavigate } from "react-router-dom";
import { HashLink } from "react-router-hash-link";

function Navbar2() {
  const [scrollY, setScrollY] = useState(0);
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  // Update scrollY state on scroll
  const handleScroll = () => {
    // console.log(window.scrollY);
    setScrollY(window.scrollY);
  };

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  console.log(window.location.pathname);

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      <motion.div
        className={`flex items-center justify-between w-full z-[999] py-3 
        duration-200
        fixed
     ${open && "bg-white"}
      ${scrollY > 2 && "shadow-md"}
       px-4 md:px-6 lg:px-8 `}
        animate={{
          // position: scrollY > 2 ? "fixed" : "relative",

          backgroundColor:
            scrollY > 2
              ? "rgba(250,250,250,1)"
              : open
              ? "#fff"
              : "rgba(250,250,250,0)",
        }}
      >
        <div className="w-20 overflow-hidden relative">
          <img src="/images/logos/colornobg.png" />
        </div>
        <ul className=" flex-row gap-8 hidden lg:flex">
          <HashLink
            to="/#home"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Home
          </HashLink>
          <HashLink
            to="/#features"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Features
          </HashLink>
          <HashLink
            to="/#howitworks"
            className="text-gray-800 hover:text-blue uppercase"
          >
            How It Works
          </HashLink>
          <HashLink
            to="/#usecases"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Use cases
          </HashLink>
          <HashLink
            to="/#pricing"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Pricing
          </HashLink>

          <HashLink
            to="/#faqs"
            className="text-gray-800 hover:text-blue uppercase"
          >
            FAQs
          </HashLink>

          <HashLink
            href="/contactus"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Contact Us
          </HashLink>
        </ul>
        <div className="w-24 hidden lg:block">
          <Button onClick={() => navigate("/login")} label="Login" />
        </div>

        {/* <Menu
          className="cursor-pointer lg:hidden"
          onClick={() => setOpen(!open)}
        /> */}
        <div className="cursor-pointer lg:hidden">
          <MenuIcon menuHidden={open} setMenuHidden={setOpen} />
        </div>
      </motion.div>

      <div
        className={`flex flex-col  w-full max-h-[90%] bg-white  gap-2 duration-300 pt-2 pb-2 fixed ${
          scrollY > 2 && "mt-20"
        } shadow-md rounded-md origin-top ${
          !open && "scale-y-0"
        } z-[99999]  overflow-y-scroll p-8 no-scrollbar `}
      >
        <ul className=" flex-col gap-6 flex">
          <HashLink
            to="/#home"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Home
          </HashLink>
          <HashLink
            to="/#features"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Features
          </HashLink>
          <HashLink
            to="/#howitworks"
            className="text-gray-800 hover:text-blue uppercase"
          >
            How It Works
          </HashLink>
          <HashLink
            to="/#usecases"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Use cases
          </HashLink>
          <HashLink
            to="/#pricing"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Pricing
          </HashLink>
          <HashLink
            to="/#faqs"
            className="text-gray-800 hover:text-blue uppercase"
          >
            FAQs
          </HashLink>
          <a
            href="/contactus"
            className="text-gray-800 hover:text-blue uppercase"
          >
            Contact Us
          </a>
        </ul>
        <div className="w-24  mt-8 mb-3">
          <Button onClick={() => navigate("/login")} label="Login" />
        </div>
      </div>
    </>
  );
}

export default Navbar2;
