import React, { useState } from "react";
import { Toaster } from "react-hot-toast";
import Navbar from "../../components/navbar";

import Input from "../../components/input";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axios from "axios";
import Button from "../../components/Button";
import usePlan from "../../hooks/usePlan";
import Navbar2 from "../../components/navbar/nav2";
import Footer from "../Footer";
import clsx from "clsx";

function Contactus() {
  const { plan } = usePlan();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      companyName: "",
      enquiry: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      await axios.post(`${process.env.REACT_APP_API_URL}/enquiry`, {
        ...data,
        plan: plan,
      });
      reset();

      toast.success("Created Successfully");
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data === "P2002"
          ? "Company already exists"
          : "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="bg-white">
      <Navbar2 />
      <div className=" bg-white w-full  px-4 md:px-8 lg:px-12 pt-32  mb-9">
        <h1 className="font-bold text-2xl mb-6">Contact Us</h1>
        <div className="  w-full grid grid-cols-1 md:grid-cols-2 gap-4">
          <Input
            label="Name"
            id="name"
            required={true}
            register={register}
            errors={errors}
          />
          <Input
            label="Email"
            id="email"
            required={true}
            register={register}
            errors={errors}
          />
          <Input
            label="Company Name"
            id="companyName"
            required={true}
            register={register}
            errors={errors}
          />
          <div>
            <label
              className="
    block
     text-sm 
     font-medium 
     leading-6
      text-lightblack"
              htmlFor={"enquiry"}
            >
              {"Enquiry"}
            </label>
            <textarea
              id="enquiry"
              {...register("enquiry", { required: true })}
              className={clsx(
                `
                
          mt-2
        form-input
        block
        w-full
        rounded-md
        border-0
        py-1.5
        px-1.5
        text-lightblack
        shadow-sm
        ring-1
        ring-inset
        ring-gray-300
        placeholder:text-gray-400
        focus:ring-2
        focus:ring-inset
        focus:ring-lightblue
        sm:text-sm
        sm:leading-6
        `,
                errors["enquiry"] && "focus:ring-rose-500"
                // disabled && "opacity-50 cursor-default"
              )}
            />
          </div>
        </div>
        <div className="max-w-[200px] mt-10 mx-auto">
          <Button
            disabled={false}
            onClick={handleSubmit(onSubmit)}
            label={"Contact Us"}
          />
        </div>
      </div>
      <Footer />
    </div>
  );
}

export default Contactus;
