import React, { useEffect } from 'react'

import MenuIcon from '../../components/MenuIcon'

import { BsRobot } from 'react-icons/bs'
import { FaUser } from 'react-icons/fa'
import { useLocation } from 'react-router-dom'
import WizilinkImage from '../../assets/logos/wizilink.png'











function ObjectionQuestions() {


    const { state: { messages, documentName, title } } = useLocation();

    useEffect(() => {
        document.title = title;
    }, []);

    const MessageComponent = ({ message }) => {
        return <div className="bg-white  py-4 px-2 rounded-md shadow-sm">
            <div className={`flex max-w-[90%] gap-2  ml-auto group relative`}>
                <div className={`order-2`}>
                    <FaUser size={20} className='text-lightblue ' />



                    <div class=" opacity-0 invisible bg-gray-300 text-gray-800 font-semibold text-xs rounded-lg py-1 px-2 absolute right-4 top-11 z-10 group-hover:opacity-100 group-hover:visible">
                        {/* {message?.user?.name} */}
                        {message?.userName}
                    </div>


                </div>
                <div className={`flex flex-col gap-1  order-1 ml-auto`}>
                    <div className={` p-2 rounded-md 'bg-blue bg-blue text-white `}>
                        <p>{message?.question}</p>
                    </div>

                </div>
            </div>
            {/* ////////Answer///////// */}
            <div className={`flex max-w-[90%] gap-2`}>
                <div className={`order-1`}>
                    {/* <BsRobot size={20} className='text-gray-500' /> */}
                    <div className="relative overflow-hidden h-[30px] w-[30px]">
                        <img src={WizilinkImage} className='object-cover' />
                    </div>

                </div>
                <div className={`flex flex-col gap-1 order-2`}>
                    <div className={`p-2 rounded-md  bg-gray-300 text-gray-900`}>
                        <p>{message?.answer}</p>
                    </div>

                </div>
            </div>
        </div>
    }



    return (


        <div className={`overflow-hidden  p-3   duration-300  h-full w-full`}>


            <div className='flex w-full h-full  '>

                <div className='h-full    w-full   border-r-[0px] relative'>

                    <div className=' p-2 flex h-[10%] bg-blue text-white rounded-md shadow-md overflow-hidden  items-center justify-between font-semibold'>
                        <MenuIcon />

                        {/* {documentData?.name} */}
                        <div className='mx-auto'>
                            {documentName}
                        </div>



                    </div>





                    <div className={`flex p-2 h-[90%] flex-col gap-2 duration-300 rounded-md  mb-4 overflow-y-scroll  no-scrollbar`}>
                        <div className='flex flex-col flex-grow gap-3  overflow-scroll no-scrollbar'>
                            <div className='' />
                            {messages.map((message) => {
                                return <MessageComponent key={message.id} message={message} />
                            })}


                        </div>


                    </div>



                </div>

            </div>
        </div>


    )
}

export default ObjectionQuestions