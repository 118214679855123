import React, { useMemo, useState } from "react";
import useQuoteModal from "../../hooks/useQuoteModal";
import Modal from "./modal";
import Input from "../input";
import { useForm } from "react-hook-form";
import toast from "react-hot-toast";
import axios from "axios";
import usePlan from "../../hooks/usePlan";
import clsx from "clsx";
function QuoteModal() {
  const QuoteModal = useQuoteModal();
  const { plan } = usePlan();
  const [isLoading, setIsLoading] = useState(false);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      companyName: "",
      enquiry: "",
    },
  });

  const onSubmit = async (data) => {
    try {
      setIsLoading(true);

      await axios.post(`${process.env.REACT_APP_API_URL}/enquiry`, {
        ...data,
        plan: plan,
      });
      reset();
      QuoteModal.onClose();
      toast.success("Created Successfully");
    } catch (error) {
      console.log(error);
      toast.error(
        error?.response?.data === "P2002"
          ? "Company already exists"
          : "Something went wrong!"
      );
    } finally {
      setIsLoading(false);
    }
  };

  const bodyContent = (
    <div className="">
      <Input
        label="Name"
        id="name"
        required={true}
        register={register}
        errors={errors}
      />
      <Input
        label="Email"
        id="email"
        required={true}
        register={register}
        errors={errors}
      />
      <Input
        label="Company Name"
        id="companyName"
        required={true}
        register={register}
        errors={errors}
      />
      <div>
        <label
          className="
    block
     text-sm 
     font-medium 
     leading-6
      text-lightblack"
          htmlFor={"enquiry"}
        >
          {"Enquiry"}
        </label>
        <textarea
          id="enquiry"
          {...register("enquiry", { required: true })}
          className={clsx(
            `
        h-32    
        form-input
        block
        w-full
        rounded-md
        border-0
        py-1.5
        px-1.5
        text-lightblack
        shadow-sm
        ring-1
        ring-inset
        ring-gray-300
        placeholder:text-gray-400
        focus:ring-2
        focus:ring-inset
        focus:ring-lightblue
        sm:text-sm
        sm:leading-6
        `,
            errors["enquiry"] && "focus:ring-rose-500"
            // disabled && "opacity-50 cursor-default"
          )}
        />
      </div>
    </div>
  );

  return (
    <Modal
      isOpen={QuoteModal.isOpen}
      onClose={QuoteModal.onClose}
      onSubmit={handleSubmit(onSubmit)}
      title={
        plan === "enterprise" || plan === "green"
          ? "Request Access"
          : "Get a Quote"
      }
      actionLabel="Enquire"
      disabled={isLoading}
      body={bodyContent}
      // footer={footerContent}
    />
  );
}

export default QuoteModal;
