import { Route, Routes, useNavigate, useNavigation } from "react-router-dom"
import Chat from "./chat"
import AddData from "./superadmin/addData"
import Documents from "./Documents"
import Analytics from "./Analytics"
import ObjectionQuestions from './ObjectionQuestions'
import { useEffect } from "react"
import useCompanyData from "../../hooks/useCompanyData"
import useDocumentModal from "../../hooks/useDocumentModal"
import useUser from "../../hooks/useUser"
import { useCallback } from "react"
import axios from "axios"
import toast from "react-hot-toast"
import Enquiries from "./superadmin/enquiries"




const NotFound = () => {
    return <div className="flex h-full w-full  items-center justify-center text-3xl font-bold">Not Found!</div>
}

export function Navigation() {

    const { user } = useUser()
    const { companyData, setCompanyData } = useCompanyData()
    const { isOpen } = useDocumentModal()


    const navigate = useNavigate()

    const getCompanyData = useCallback(
        async () => {

            try {
                const comp = await axios.get(`${process.env.REACT_APP_API_URL}/company/${user?.companyId}`)

                setCompanyData(comp.data)
            } catch (error) {
                console.log(error)
                // toast.error('Something went wrong')

            }
        },
        [user],
    )

    useEffect(() => {
        if (user.role !== 'SUPERADMIN') { getCompanyData() }
    }, [isOpen, navigate])

    useEffect(() => {

        if (!user) {
            navigate("/folio");
        }
        if (user.role === 'SUPERADMIN') { navigate("/admin"); }
        if (user.role === 'ADMIN') { navigate("/documents"); }
        if (user.role === 'USER') { navigate("/chat"); }


    }, [])


    return (
        <div className="h-full">
            <Routes >
                <Route path="/chat" element={<Chat />} />
                <Route path="/admin" element={<AddData />} />
                <Route path="/enquiry" element={<Enquiries />} />
                <Route path="/documents" element={<Documents />} />
                <Route path="/analytics" element={<Analytics />} />
                <Route path="/records" element={<ObjectionQuestions />} />


                <Route path="*" element={<NotFound />} />
            </Routes>
        </div>
    )
}