import React, { useEffect } from 'react'
import { Toaster } from 'react-hot-toast'

import Home from './screens/Home'
import Navbar from './components/navbar'
import Features from './screens/Features'
import HowItWorks from './screens/HowItWorks'
import Usecases from './screens/usecases'
import Pricing from './screens/pricing'
import Footer from './screens/Footer'
import Carousel from './components/Carousel'
import All from './All'

function Folio() {
  useEffect(() => {
    document.title = 'Wizilink';
  }, []);
  return (
    <div >
      <Toaster />
      <Navbar />
      <div className="  flex flex-col ">

        <All />
      </div>


    </div>
  )
}

export default Folio