import React, { useState, useEffect } from 'react'

import DocumentModal from '../../components/modals/documentModal';
import useDocumentModal from '../../hooks/useDocumentModal';
import useCompanyData from '../../hooks/useCompanyData';
import MenuIcon from '../../components/MenuIcon';
import { IoIosAddCircleOutline } from 'react-icons/io'

function Documents() {


    const documentModal = useDocumentModal()
    const { companyData } = useCompanyData()


    useEffect(() => {
        document.title = 'Documents';
    }, []);



    return (


        <div className={`overflow-hidden  p-3   duration-300  h-full w-full`}>


            <div className='flex w-full h-full  '>

                <div className='h-full    w-full   border-r-[0px] relative'>

                    <div className=' p-2 flex h-[10%] bg-blue text-white rounded-md shadow-md overflow-hidden  items-center justify-between font-semibold'>
                        <MenuIcon />

                        {companyData?.name}'s Documents

                        <IoIosAddCircleOutline onClick={() => { documentModal.onOpen() }} className={`text-[#eaeaea] duration-300 cursor-pointer`} size={28} />
                    </div>



                    <div className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg-[#eaeaea]  max-h-[90%]  gap-2 duration-300 pt-2 pb-2 w-full rounded-md origin-top    overflow-y-scroll p-0 no-scrollbar`}>

                        {companyData?.documents?.map((document) => <div onClick={() => {

                        }} key={document.id} className={`p-3 w-full h-16 text-md font-[500] rounded-md flex items-center   bg-lightblue text-white shadow-sm border-[0px] `}>
                            {document.name?.split('-')[0]}
                        </div>)}
                    </div>


                </div>

            </div>
            <DocumentModal />
        </div>
    )
}

export default Documents