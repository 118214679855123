import React from "react";

function FeatureCard({ Icon, title, description, left }) {
  return (
    <div className="flex z-[50]">
      <div
        className={`${left && "order-2"}  ${
          left ? "ml-1" : "mr-1"
        } min-w-[56px] w-14 h-14 duration-300 border-2 border-transparent hover:border-blue hover:bg-blue hover:bg-opacity-[0.15] rounded-md hover:rounded-[50%] flex items-center justify-center`}
      >
        <Icon className={`text-blue `} size={32} />
      </div>
      <div className="flex flex-col  ml-2 ">
        <h1
          className={`${
            left ? "text-right" : "text-left"
          } mt-3 text-xl font-semibold text-gray-800`}
        >
          {title}
        </h1>
        <p
          className={`${
            left ? "text-right" : "text-left"
          } text-sm text-gray-500 `}
        >
          {description}
        </p>
      </div>
    </div>
  );
}

export default FeatureCard;
