import React from "react";

function HowItWorksCard({ Icon, description, title }) {
  return (
    <div className="flex items-center group">
      <div
        className={` min-w-[56px] w-14 h-14 duration-300 border-2 border-transparent hover:border-blue hover:bg-blue hover:bg-opacity-[0.15] rounded-md hover:rounded-[50%] flex items-center justify-center`}
      >
        <Icon
          className={`group-hover:text-blue text-gray-500 font-bold`}
          size={30}
        />
      </div>
      <div className="flex flex-col  ml-2 ">
        <p className={`text-[15px] text-gray-500 hover:text-gray-600 `}>
          <span className="font-semibold text-gray-600">{title}</span>
          {description}
        </p>
      </div>
    </div>
  );
}

export default HowItWorksCard;
