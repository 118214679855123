import React, { useState, useEffect, useRef } from "react";

import DocumentModal from "../../components/modals/documentModal";
import useDocumentModal from "../../hooks/useDocumentModal";
import useCompanyData from "../../hooks/useCompanyData";
import MenuIcon from "../../components/MenuIcon";
import { IoIosAddCircleOutline } from "react-icons/io";
import { useNavigate } from "react-router-dom";
import Chart from "react-apexcharts";
import ApexCharts from "apexcharts";
import StatComponent from "../../components/StatComponent";
import CanvasJSReact from "@canvasjs/react-charts";
import axios from "axios";

function Analytics() {
  const { companyData } = useCompanyData();
  const [chartData, setChartData] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    document.title = "Analytics";
  }, []);

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/user/totalQues/${companyData?.id}`)
      .then((res) => {
        const dataPoints = res?.data.map((user) => ({
          y: user.totalQuestions,
          label: user.name,
        }));
        setChartData(dataPoints);
      })
      .catch((error) => console.log(error));
  }, [companyData]);

  var CanvasJS = CanvasJSReact.CanvasJS;
  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  const addSymbols = (e) => {
    var suffixes = ["", "K", "M", "B"];
    var order = Math.max(
      Math.floor(Math.log(Math.abs(e.value)) / Math.log(1000)),
      0
    );
    if (order > suffixes.length - 1) order = suffixes.length - 1;
    var suffix = suffixes[order];
    return CanvasJS.formatNumber(e.value / Math.pow(1000, order)) + suffix;
  };

  const options = {
    animationEnabled: true,
    theme: "light2",
    title: {
      text: "Participants Activity",
    },
    axisX: {
      // title: "Social Network",
      reversed: true,
    },
    axisY: {
      title: "Total Questions by Users",
      includeZero: true,
      labelFormatter: addSymbols,
    },
    data: [
      {
        type: "bar",
        dataPoints: chartData,
      },
    ],
  };

  return (
    <div className={`overflow-hidden  p-3   duration-300  h-full w-full`}>
      <div className="flex w-full h-full">
        <div className="h-full w-full border-r-[0px] relative">
          <div className=" p-2 flex h-[10%] bg-blue text-white rounded-md shadow-md overflow-hidden  items-center justify-between font-semibold">
            <MenuIcon />
            {/* {companyData?.name}'s Documents */}
            <div className="mr-auto ml-auto">Analytics</div>
            {/* <IoIosAddCircleOutline onClick={() => { }} className={`text-[#eaeaea] duration-300`} size={28} /> */}
          </div>
          <div
            className={`grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 bg-[#eaeaea]  max-h-[90%]  gap-2 duration-300 pt-2 pb-2 w-full rounded-md origin-top    overflow-y-scroll p-0 no-scrollbar`}
          >
            {companyData?.documents?.map((document) => (
              <StatComponent navigate={navigate} document={document} />
            ))}
            {chartData.length === 0 ? (
              <div
                className={`p-3 w-full  text-md font-[500] rounded-sm flex flex-col  bg-white   text-gray-600 shadow-lg border-[0px] `}
              >
                <div>Total Questions by Users</div>
                <div className="flex items-center justify-center w-full h-full">
                  No Stats to Show!
                </div>
              </div>
            ) : (
              <CanvasJSChart options={options} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
}

export default Analytics;
