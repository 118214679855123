import React, { useCallback, useEffect, useState } from "react";
import { MdDeleteOutline } from 'react-icons/md'
import { IoIosCloseCircleOutline } from 'react-icons/io'
import logo from "../../../assets/logos/colornobg.png";
import CompanyModal from "../../../components/modals/CompanyModal";
import Button from "../../../components/Button";
import useCompanyModal from "../../../hooks/useCompanyModal";
import useMemberModal from "../../../hooks/useMemberModal";
import MemberModal from "../../../components/modals/MemberModal";
import axios from "axios";
import toast from 'react-hot-toast'
import useUser from "../../../hooks/useUser";
import { BsSendFill } from 'react-icons/bs'
import { Link } from "react-router-dom";

const Company = ({ company, setIsLoading, isLoading }) => {
    const [isOpen, setIsOpen] = useState(false)



    const deleteUser = async (id) => {

        try {
            setIsLoading(true)

            await axios.delete(`${process.env.REACT_APP_API_URL}/user/${id}`)

            toast.error('Deleted')

        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!')
        }
        finally {
            setIsLoading(false)
        }

    }



    const deleteCompany = async (id) => {

        try {
            setIsLoading(true)

            await axios.delete(`${process.env.REACT_APP_API_URL}/enquiry/${id}`)

            toast.error('Deleted')

        } catch (error) {
            console.log(error)
            toast.error('Something went wrong!')
        }
        finally {
            setIsLoading(false)
        }


    }



    return (
        <div onClick={() => {
            // setIsOpen(!isOpen)
        }} className={`p-2 w-full duration-500    max-h-[300px] rounded-md ${isOpen && 'bg-white'} bg-grey m-2 text-gray-900`}>

            <div className={'flex flex-row pr-2 justify-between items-center'}>
                <div className={`flex flex-col`}>
                    <h1 className="font-semibold">{company.name}</h1>
                    <p className={'text-sm text-gray-600 font-light -mt-1 ml-1'}>{company?.email}</p>
                </div>
                <div className="flex flex-row gap-3">
                    {/* <Link to='#' onClick={(e) => {
                        e.stopPropagation()

                        window.location.href = "mailto:no-reply@example.com"
                        e.preventDefault();
                        // deleteCompany(company?.id)
                    }} className="cursor-pointer pl-0">
                        <BsSendFill className={`text-blue mt-[2px]`} size={19} />
                    </Link> */}
                    <div onClick={(event) => {
                        event.stopPropagation()
                        deleteCompany(company?.id)
                    }} className="cursor-pointer pl-0 ">
                        <MdDeleteOutline className={`${isOpen ? 'text-blue' : 'text-rose-500'}`} size={22} />
                    </div>
                </div>
            </div>
            <p className={'text-md font-semibold text-gray-800 mt-2'}>Plan <span className={'text-sm text-gray-700 capitalize'}>{company.plan}</span></p>
            <p className="p-2 text-sm ">{company.enquiry}</p>

            {/* <div className={`flex duration-300 flex-col ${isOpen && 'p-1'} font-normal  text-gray-600 overflow-scroll origin-top max-h-0  ${!isOpen ? 'scale-y-0 opacity-0' : 'max-h-[240px]'}`}>
                {company?.users?.map(user => <div className={`${isOpen && 'p-1'} bg-gray-200 rounded-md flex flex-row items-center justify-between mt-1`}>
                    <div className="flex flex-col ">
                        <div className=""> {user?.name}</div>
                        <div className="text-[12px]  text-gray-500 "> {user?.email}</div>
                    </div>
                    <div onClick={(event) => {
                        event.stopPropagation()
                        deleteUser(user?.id)

                    }} className="cursor-pointer pl-2">
                        <IoIosCloseCircleOutline className="text-rose-500" size={22} />
                    </div>
                </div>)}


            </div> */}
        </div>
    );
};

function Enquiries() {

    const companyModal = useCompanyModal()

    const memberModal = useMemberModal()
    const [companies, setCompanies] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        document.title = 'Admin';
    }, []);


    const getCompanies = useCallback(


        async () => {
            console.log('Get All Companies')
            try {
                const comps = await axios.get(`${process.env.REACT_APP_API_URL}/enquiry`)

                setCompanies(comps.data)
            } catch (error) {
                console.log(error)
                toast.error('Something went wrong')

            }
        },
        [companies,],
    )


    useEffect(() => {
        if (!isLoading) {
            getCompanies()
        }

    }, [companyModal, memberModal, isLoading])




    return (
        <div
            className="
            m-3
    flex 
    min-h-[100vh]
    flex-col
    items-center
    w-full
    sm:px-6
    lg:px-8
    bg-lightgrey
    relative
    
    "

        >


            <div className="w-full grid grid-cols-1 lg:grid-cols-2 gap-2  p-5 py-2 ">
                {companies?.map((company, index) => {
                    return <Company isLoading={isLoading} setIsLoading={setIsLoading} company={company} />;
                })}
            </div>

            <CompanyModal />
            <MemberModal companies={companies} />
        </div>
    );
}

export default Enquiries;
