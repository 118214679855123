import React from "react";
import { Sparkle, Users2 } from "lucide-react";
import Button from "./Button";
import useQuoteModal from "../hooks/useQuoteModal";
import QuoteModal from "./modals/quoteModal";
import usePlan from "../hooks/usePlan";

function PricingCard({ title, features, price, custom, plan, green }) {
  const { onOpen } = useQuoteModal();
  const { setPlan } = usePlan();

  return (
    <div
      className={`flex flex-col items-center border-[1px] rounded-lg border-blue ${
        green
          ? "bg-[#e3f5e0] hover:bg-opacity-50"
          : "hover:bg-blue hover:bg-opacity-10"
      } py-6 px-4 duration-300 group  `}
    >
      <h1 className="text-4xl text-blue font-bold group-hover:scale-[1.2] duration-300">
        {`${!custom ? "£" : ""}`}
        {price}
        {/* {!custom && <sup className="font-normal">£</sup>} */}
      </h1>

      <h1 className="my-2 text-xl font-bold">{title}</h1>

      <ul className="flex flex-col items-center gap-12 mt-8 ">
        {features.map((feature) => (
          <li className="flex flex-row w-[100%]  lg:w-[70%] ">
            <Users2 className="text-lightblue min-w-[30px] mr-2" />
            <p className="">{feature}</p>
          </li>
        ))}

        <li className="flex flex-row w-[100%]  lg:w-[70%] ">
          <Button
            label="Enquire"
            onClick={() => {
              console.log(plan);
              setPlan(plan);
              onOpen();
            }}
          />
        </li>
      </ul>

      <QuoteModal />
    </div>
  );
}

export default PricingCard;
