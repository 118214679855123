import React, { useEffect } from "react";
import Container from "../../components/Container";
import logo from '../../assets/logos/colornobg.png'
import AuthForm from "../../components/AuthForm";

function Login() {

  useEffect(() => {
    document.title = 'Login';
  }, []);

  return (
    <div
      className="
    flex 
    min-h-[100vh]
    flex-col
    justify-center
    py-12
    sm:px-6
    lg:px-8
    bg-lightgrey
    "
    >
      <div className="
      sm:mx-auto
      sm:w-full
      sm:max-w-md
      
     
      ">
        <img src={logo} width='96' height='96' className="mx-auto" />
        <h2 className="
        mt-3 
        text-center
        text-2xl
        font-bold
        tracking-tight
        text-lightblack
       
        ">
          Sign in to your account
        </h2>
      </div>
      <AuthForm />
    </div>
  );
}

export default Login;
