import React, { useEffect, useRef, useState } from "react";

import { BsSendFill } from "react-icons/bs";
import { FaUser } from "react-icons/fa";

import SyncLoader from "react-spinners/SyncLoader";

import axios from "axios";

import { AiOutlineDislike } from "react-icons/ai";
import { AiOutlineLike } from "react-icons/ai";

const messagesTemp = [
  {
    id: "1",
    text: "What is your name?",
    from: "user",
  },
  {
    id: "2",
    text: "My name is Chatbot.",
    from: "ai",
  },
  {
    id: "3",
    text: "How are you?",
    from: "user",
  },
  {
    id: "4",
    text: `I'm doing well, thank you!`,
    from: "ai",
  },
  {
    id: "5",
    text: "What is the capital of France?",
    from: "user",
  },
  {
    id: "6",
    text: "The capital of France is Paris.",
    from: "ai",
  },
  {
    id: "7",
    text: "Tell me a joke.",
    from: "user",
  },
  {
    id: "8",
    text: `Why don't scientists trust atoms? Because they make up everything!`,
    from: "ai",
  },
  {
    id: "9",
    text: "What is the weather like today?",
    from: "user",
  },
  {
    id: "10",
    text: "The weather is sunny and warm.",
    from: "ai",
  },
  {
    id: "11",
    text: "Can you recommend a good movie?",
    from: "user",
  },
  {
    id: "12",
    text: 'Sure! I recommend "Inception".',
    from: "ai",
  },
  {
    id: "13",
    text: "How can I learn programming?",
    from: "user",
  },
  {
    id: "14",
    text: "You can start by learning the basics of a programming language and practicing coding exercises.Choose a programming language: Select a programming language based on your interests and the type of applications you want to build. Popular options include Python, JavaScript, Java, C++, and Ruby.",
    from: "ai",
  },
  {
    id: "15",
    text: "What is the meaning of life?",
    from: "user",
  },
  {
    id: "16",
    text: "The meaning of life is subjective and can vary for each individual.",
    from: "ai",
  },
  {
    id: "17",
    text: "Do you have any pets?",
    from: "user",
  },
  {
    id: "18",
    text: "As an AI, I don't have pets, but I love all animals!",
    from: "ai",
  },
  {
    id: "19",
    text: "How do I bake a cake?",
    from: "user",
  },
  {
    id: "20",
    text: "To bake a cake, you need to gather the ingredients, mix them together, and bake the batter in the oven.",
    from: "ai",
  },
  {
    id: "1",
    text: "What is your name?",
    from: "user",
  },
  {
    id: "2",
    text: "My name is Chatbot.",
    from: "ai",
  },
  {
    id: "3",
    text: "How are you?",
    from: "user",
  },
  {
    id: "4",
    text: `I'm doing well, thank you!`,
    from: "ai",
  },
  {
    id: "5",
    text: "What is the capital of France?",
    from: "user",
  },
  {
    id: "6",
    text: "The capital of France is Paris.",
    from: "ai",
  },
  {
    id: "7",
    text: "Tell me a joke.",
    from: "user",
  },
  {
    id: "8",
    text: `Why don't scientists trust atoms? Because they make up everything!`,
    from: "ai",
  },
  // Add more objects here...
];

function Chat() {
  const [documentData, setDocumentData] = useState({});

  const textareaRef = useRef("");
  const messageScrollRef = useRef(null);
  const [inputText, setInputText] = useState("");
  const [messages, setMessages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setMessages([
      {
        id: "1",
        text: `Welcome to Wizilink's Demo Chatbot! 
                Say goodbye to scrolling for information - ask us anything about our services and company.`,
        from: "ai",
        welcome: true,
      },
    ]);
  }, []);

  function autoGrow(e) {
    setInputText(e?.target?.value);
    const textarea = textareaRef.current;
    textarea.style.height = "auto";
    textarea.style.height = textarea.scrollHeight + "px";
  }

  const askQuestion = async () => {
    if (inputText === "") {
      return;
    }

    try {
      setIsLoading(true);
      const reqData = {
        query: inputText,
      };
      setMessages([
        ...messages,
        {
          id: "8",
          text: inputText,
          from: "user",
        },
      ]);
      setInputText("");

      const messagesTempo = [
        ...messages,
        {
          id: "8",
          text: inputText,
          from: "user",
        },
      ];

      // setTimeout(() => {

      let answer = null;

      let resp = await axios.post(
        `${process.env.REACT_APP_API_URL}/question/getQuestion`,
        {
          question: inputText,
          documentId: "64ca10975e5fda2515d326ef",
        }
      );

      if (resp?.data?.answer) {
        answer = resp?.data?.answer;
      } else {
        let resp = await axios.post(
          `${process.env.REACT_APP_AI_URL}/homepage`,
          reqData
        );
        answer = resp?.data?.response;
      }

      if (answer) {
        await axios.post(`${process.env.REACT_APP_API_URL}/question`, {
          question: inputText,
          documentId: "64ca10975e5fda2515d326ef",
          userName: "anonymous",
          answer: answer,
        });

        setMessages([
          ...messagesTempo,
          {
            id: "9",
            // text: randomSentence({ min: 4, max: 100 }),
            text: answer,
            like: false,
            dislike: false,
            from: "ai",
          },
        ]);
      }

      setIsLoading(false);

      if (messageScrollRef.current) {
        // messageScrollRef.current.scrollIntoView({ behavior: "smooth" });
      }

      // }, 3000);
    } catch (error) {
      setIsLoading(false);
      // toast.error('Something went wrong')
      console.log(error);
    }
  };

  const MessageComponent = ({ message, index }) => {
    return (
      <div
        className={`flex max-w-[90%] gap-2 ${
          message.from === "user" && "ml-auto"
        }`}
      >
        <div className={`${message.from === "user" ? "order-2" : "order-1"}`}>
          {message.from === "user" ? (
            <FaUser size={20} className="text-lightblue" />
          ) : (
            //  <BsRobot size={20} className='text-gray-500' />
            // <img src={WizilinkImage} width={30} height={30} />
            <div className="relative overflow-hidden h-[30px] w-[30px]">
              <img src="/images/logos/wizilink.png" className="object-cover" />
            </div>
          )}
        </div>
        <div
          className={`flex flex-col gap-1 ${
            message.from === "user" ? "order-1" : "order-2"
          }`}
        >
          <div
            className={` p-2 rounded-md whitespace-pre-line text-[12px] md:text-[13px] ${
              message.from === "user"
                ? "bg-blue text-white"
                : " bg-gray-300 text-gray-900"
            } `}
          >
            {message.text}
          </div>
          {/* {message?.from === "ai" && !message?.welcome && (
            <div className={`order-2 ml-auto mr-2 flex flex-row gap-2`}>
              <AiOutlineLike
                onClick={() => likeMessage(message, index)}
                className={`${message?.like === true && "text-blue"}`}
              />
              <AiOutlineDislike
                className={`${message.dislike === true && "text-blue"}`}
                onClick={() => dislikeMessage(message, index)}
              />
            </div>
          )} */}
        </div>
      </div>
    );
  };

  const SuggestionComponent = ({ question }) => {
    return (
      <div
        onClick={() => {
          setInputText(question?.question);
        }}
        className="cursor-pointer bg-gray-500 text-white rounded-full p-1 pl-2 pr-2  text-sm"
      >
        {question?.question}
      </div>
    );
  };

  const getDocument = async () => {
    try {
      const doc = await axios.get(
        `${process.env.REACT_APP_API_URL}/document/64ca10975e5fda2515d326ef`
      );

      setDocumentData(doc?.data);
    } catch (error) {
      console.log(error);
      // toast.error('Something went wrong')
    }
  };

  useEffect(() => {
    getDocument();
  }, [isLoading]);
  return (
    <div
      className={`overflow-hidden  p-0   duration-300  h-full w-full bg-white rounded-sm`}
    >
      <div className="flex w-full h-full  ">
        <div className="h-full    w-full   border-r-[0px] relative">
          <>
            <div
              className={`flex p-2 h-[100%]  flex-col gap-2 duration-300 rounded-md  mb-4 overflow-y-scroll  no-scrollbar`}
            >
              <div className="flex flex-col flex-grow gap-3  overflow-scroll no-scrollbar">
                <div className="mt-0" />
                {messages.map((message, index) => {
                  return (
                    <MessageComponent
                      key={message.id}
                      index={index}
                      message={message}
                    />
                  );
                })}

                <div ref={messageScrollRef} />
              </div>
              {
                // <div className="flex flex-row flex-wrap gap-2">
                //   {documentData?.questions?.map((question) => (
                //     <SuggestionComponent question={question} />
                //   ))}
                // </div>
              }
              <div className="mt-auto  flex flex-row items-center gap-2">
                {/* <textarea
                  placeholder="Talk with your document ..."
                  ref={textareaRef}
                  value={inputText}
                  onChange={autoGrow}
                  className="p-2 w-full outline-none  h-0 rounded-md bg-gray-300 min-h-[32px] max-h-[300px] overflow-y-auto"
                /> */}
                <input
                  placeholder="Ask anything ..."
                  onChange={(e) => setInputText(e.target.value)}
                  value={inputText}
                  className="p-2 w-full outline-none  h-0 rounded-md bg-gray-300 min-h-[32px] max-h-[300px] overflow-y-auto"
                />

                {isLoading ? (
                  <SyncLoader
                    color="#137DC5"
                    size={7}
                    className={` mt-auto mb-4 z-0 `}
                  />
                ) : (
                  <BsSendFill
                    onClick={() => {
                      askQuestion();
                    }}
                    className={`text-blue  duration-300 mt-auto z-0 cursor-pointer`}
                    size={28}
                  />
                )}
              </div>
            </div>
          </>
        </div>
      </div>
    </div>
  );
}

export default Chat;
