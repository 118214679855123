import React from "react";
import { motion } from "framer-motion";
import FeatureCard from "../../components/featureCard";
import {
  ArrowUpNarrowWide,
  Languages,
  BarChartBig,
  ShieldCheck,
} from "lucide-react";

function Features() {
  const headingAnimationVariant = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };
  const leftFeaturesVariants = {
    initial: {
      opacity: 0,
      x: -200,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
      },
    },
  };
  const rightFeaturesVariants = {
    initial: {
      opacity: 0,
      x: 200,
    },
    animate: {
      opacity: 1,
      x: 0,
      transition: {
        duration: 1,
      },
    },
  };
  const centerFeaturesVariants = {
    initial: {
      opacity: 0,
      scale: 0.4,
    },
    animate: {
      opacity: 1,
      scale: 0.9,
      transition: {
        duration: 1,
      },
    },
  };

  return (
    <div className="relative">
      <div id="features" className=" absolute -top-28" />
      <div className="w-screen max-w-[100vw] overflow-hidden  px-4 md:px-8 lg:px-12 pb-32">
        <div className="flex items-center gap-2 mb-20 ">
          <div className="h-[1px] rounded-full bg-black w-full" />
          <motion.h1
            className="min-w-[200px] text-center"
            variants={headingAnimationVariant}
            initial="initial"
            whileInView="animate"
          >
            Wizilink App Features
          </motion.h1>
          <div className="h-[1px] rounded-full bg-black w-full" />
        </div>

        <div className="grid grid-cols-1 md:grid-cols-3 mt-4  ">
          <motion.div
            variants={leftFeaturesVariants}
            initial="initial"
            whileInView="animate"
            className=" flex flex-col items-center justify-between"
          >
            <FeatureCard
              Icon={ArrowUpNarrowWide}
              title="Flexible Platform"
              description={`Wizilink offers an AI-powered training platform with interactive 
              Q&A, context-based document retrieval, and advanced analytics capabilities. 
              Empowering employees at their desk or in the field, our platform enhances training 
              effectiveness and knowledge management for businesses.`}
              left={true}
            />

            <FeatureCard
              Icon={Languages}
              title="Tailored Language Model (LLM)"
              description={`Our proprietary LLM elevates the platform's 
              intelligence, delivering accurate and context-specific answers to user questions. With a 
              focus on enhancing the user experience and knowledge retrieval process, Wizilink 
              ensures seamless and precise information access.`}
              left={true}
            />
          </motion.div>
          <div className=" flex items-center justify-center overflow-hidden relative">
            <motion.img
              variants={centerFeaturesVariants}
              initial="initial"
              whileInView="animate"
              src="/images/features2.png"
              className="relative overflow-hidden rounded-xl"
            />
          </div>

          <motion.div
            variants={rightFeaturesVariants}
            initial="initial"
            whileInView="animate"
            className=" flex flex-col items-center justify-between"
          >
            <FeatureCard
              Icon={BarChartBig}
              title="Actionable Analytics"
              description={`Unlock valuable insights into user behaviour, frequently asked 
                questions, and knowledge gaps with Wizilink's built-in analytics. Identify training needs, 
                optimise processes, and improve performance, making informed decisions that drive 
                your team's success.`}
              left={false}
            />
            <FeatureCard
              Icon={ShieldCheck}
              title="Privacy and Data Security Measures"
              description={`Wizilink prioritises your data privacy. Our 
              sophisticated, private language model safeguards your information from unauthorised 
              access. Rest assured, we never utilise user data to train our language models or share 
              any data with third parties. Your trust and privacy are of utmost importance to us.`}
              left={false}
            />
          </motion.div>
        </div>
      </div>
    </div>
  );
}

export default Features;
