import React, { useState } from "react";
import { motion } from "framer-motion";
import { TbCircleChevronRight } from "react-icons/tb";

function Faqs() {
  const questions = [
    {
      question: "What is Wizilink, and how does it work?",
      answer: `Wizilink is an innovative management app designed to improve team collaboration and knowledge sharing, without the constraints of geographical boundaries. It utilises AI technology to streamline your team's workflow and facilitate efficient information retrieval. Managers can upload company documents, and users can ask natural language questions to receive fast and accurate responses, fostering seamless teamwork and information exchange.`,
    },
    {
      question: `How does Wizilink ensure the accuracy of responses to user 
      questions?`,
      answer: `While our AI technology is continually improving and provides accurate responses, we want to emphasise that AI is not infallible, and the answers generated may not always be perfect. Wizilink is designed to be a helpful tool,but it is not intended to replace human expertise.

      We employ state-of-the-art AI models and rigorous testing to ensure the accuracy of responses. However, since AI models learn from vast datasets, they may occasionally produce responses that require human verification. To address this, we encourage clients to review and validate the information provided by Wizilink, especially in critical or sensitive situations.

  Human checking and oversight are essential components of our platform. We advocate for users to verify information to maintain the highest level of accuracy and reliability. This collaborative approach between AI and human expertise ensures that Wizilink can be a valuable resource while also recognising the significance of human input in decision-making processes.`,
    },
    {
      question: `How does Wizilink ensure data privacy and security?`,
      answer: `Wizilink takes data privacy seriously. Managers can see employee questions to gain actionable insights and improve training effectiveness, but these questions are anonymised to protect employee privacy. 

      As the guardian of your data privacy, Wizilink operates with utmost confidentiality and security. Our sophisticated, private language model ensures that your information remains safe and inaccessible to any unauthorised parties. 

      We adhere strictly to ethical practices, never utilising user data to train our language models, safeguarding your IP.`,
    },
    {
      question: `What payment methods do you accept for the subscription? `,
      answer: `We accept various payment methods, including major credit cards and online payment platforms. During the sign-up process, you can choose the payment option that best suits your organisation's preferences.`,
    },
    {
      question: `Are there any hidden fees or additional costs in the pricing plans?`,
      answer: `No, Wizilink's pricing plans are transparent, and there are no hidden fees. The pricing mentioned for each tier includes all the features and services offered within that plan.`,
    },
    {
      question: `Can I switch between pricing tiers as my team grows?`,
      answer: `Yes, absolutely! We understand that your organisation's needs may evolve over time. You can easily upgrade or downgrade your pricing tier to accommodate changes in your team size and requirements and our sales team will work with you to tailor a solution that aligns with your evolving business objectives.`,
    },
    {
      question: `What level of customer support is included with each pricing tier?`,
      answer: `All our pricing tiers include customer support to assist you with any inquiries or technical issues you may encounter. However, our Enterprise Tier offers additional support options, such as dedicated support and customisation, to meet the unique needs of large-scale organisations.`,
    },
    {
      question: `Can Wizilink replace the need for human managers?`,
      answer: `Far from it. Wizilink empowers line managers with visibility and actionable data to bridge knowledge gaps and drive team success. It addresses the pain point of managing knowledge gaps and team performance, assuring businesses that line managers can access insights on user questions and downvoted replies, enabling effective training and team management.`,
    },
    {
      question: `How does Wizilink contribute to a positive work environment?`,
      answer: `Wizilink fosters a supportive and judgment-free atmosphere for employees, enabling them to ask questions without feeling pressured to seek help from others. It assures businesses that Wizilink promotes a culture of continuous learning and growth, empowering every team member to access information confidently, thus promoting a more efficient and productive work environment.`,
    },
    {
      question: `Does Wizilink require special training to use?`,
      answer: `No, Wizilink is designed to be user-friendly and intuitive, empowering line managers with the visibility and actionable data to drive team success. It assures businesses that there's no need for special training or technical expertise. Simply upload your company documents and start interacting with the app naturally to retrieve the information you need quickly and effortlessly.`,
    },
    {
      question: `Can I access Wizilink from multiple devices?`,
      answer: `Yes! Wizilink is a cloud-based app, enabling you to access it from multiple devices with an internet connection, perfect for your remote employees. You can use Wizilink on your computer, smartphone, or tablet, ensuring seamless access to essential information wherever your team are.`,
    },
    {
      question: `How can I provide feedback or suggest new features?`,
      answer: `We really do welcome your feedback and suggestions! Please email us at support@wizilink.com to provide feedback or share ideas for new features. Your input is invaluable as we continually strive to improve Wizilink and meet you and your business’s  needs.`,
    },
  ];

  const headingAnimationVariant = {
    initial: {
      opacity: 0,
      scale: 0.5,
    },
    animate: {
      opacity: 1,
      scale: 1,
      transition: {
        duration: 0.5,
      },
    },
  };

  const FaqCard = ({ question }) => {
    const [isOpen, setIsOpen] = useState(false);
    return (
      <div
        onClick={() => setIsOpen(!isOpen)}
        className={`p-2 md:w-[70%] mx-auto duration-500   font-semibold max-h-[600px] rounded-md ${
          isOpen && "bg-white"
        } bg-grey m-2 text-gray-900`}
      >
        <div className={"flex flex-row pr-2 justify-between items-center"}>
          <div className={`${isOpen && "text-blue"}`}>{question.question}</div>
          <TbCircleChevronRight
            onClick={() => setIsOpen(!isOpen)}
            className={`cursor-pointer min-w-[28px]   ${
              isOpen ? "rotate-90 text-blue" : "text-gray-700"
            } duration-300`}
            size={28}
          />
        </div>

        <div
          className={`flex duration-300 flex-col ${
            isOpen && "p-1"
          } font-normal  text-gray-600 overflow-scroll origin-top max-h-0  ${
            !isOpen ? "scale-y-0 opacity-0" : "max-h-[540px]"
          }`}
        >
          <div
            className={`${
              isOpen && "p-1"
            } bg-gray-200 rounded-md flex flex-row items-center justify-between mt-1`}
          >
            <div className="whitespace-pre-line"> {question.answer}</div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="relative">
      <div id="faqs" className=" absolute -top-28" />
      <div className="w-screen max-w-[100vw] overflow-hidden  px-4 md:px-8 lg:px-12 pb-32 py-4  ">
        <div className="flex items-center gap-2 mb-10 justify-center">
          {/* <div className="h-[1px] rounded-full bg-black w-full" /> */}
          <motion.h1
            className="min-w-[200px] text-center font-bold text-2xl"
            variants={headingAnimationVariant}
            initial="initial"
            whileInView="animate"
          >
            FAQs
          </motion.h1>
          {/* <div className="h-[1px] rounded-full bg-black w-full" /> */}
        </div>

        {questions.map((question) => (
          <FaqCard question={question} />
        ))}
      </div>
    </div>
  );
}

export default Faqs;
