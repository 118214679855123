import React, { useState } from 'react'
import Input from './Input'
import { useForm, } from 'react-hook-form'
import Button from './Button'
import CompanyModal from './modals/CompanyModal'
import toast from 'react-hot-toast'
import axios from 'axios'
import { decodeToken } from "react-jwt";
import useUser from '../hooks/useUser'



function AuthForm() {

    const { register, handleSubmit, reset, formState: { errors } } = useForm()
    const { setUser } = useUser()


    const [isLoading, setIsLoading] = useState(false)

    const onSubmit = async (data) => {

        try {
            setIsLoading(true)
            let user = await axios.post(`${process.env.REACT_APP_API_URL}/user/login`, data)


            const tokenReturned = user?.data?.token
            if (!tokenReturned) {
                toast.error('Invalid Credentials')
            } else {

                localStorage.setItem('jwt_token', tokenReturned)
                const decodedToken = decodeToken(tokenReturned)

                setUser({ ...decodedToken })
                toast.success('Logged In')
                reset()
            }


        } catch (error) {
            console.log(error)
            toast.error('Something Went Wrong')
        }
        finally {
            setIsLoading(false)
        }
    }


    return (
        <div className='
        mt-8
        sm:mx-auto
        sm:w-full
        sm:max-w-md
        '>
            <div className='
            bg-white
            mx-2
            rounded-md
            px-4
            py-8
            shadow
            sm:rounded-lg
            sm:px-10
            '>
                <form className='space-y-6'>
                    <Input id='email' label='Email' required register={register} errors={errors}

                    />
                    <Input id='password' label='Password' type='password' required register={register} errors={errors}

                    />
                    <div className='w-[90%] mx-auto'>
                        <Button disabled={isLoading} label='Login' onClick={handleSubmit(onSubmit)} />
                    </div>

                </form>
            </div>

        </div>
    )
}

export default AuthForm